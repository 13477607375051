import {
    ButtonPrimary,
    Icon,
    InputSelect,
    InputText,
    PaginationPrimary,
    Table,
    useAsyncEffect
} from '@lambdacurry/component-library';
import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { debounce, orderBy } from 'lodash';
import { DateTime } from 'luxon';
import React, { FC, useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { ActionList, AppCard, AppListEmpty } from '../../../components-v2/shared';
import { friendlyUtmSourceNames } from '../../../constants';
import { getRouteWithContext } from '../../../routes/routeUtils';
import useStore from '../../../store/useStore';
import { LeadStatus, LeadType } from '../../../types';
import { handlePromise } from '../../../util/async';
import { phoneFormatter } from '../../../util/formatters';
import { Filters } from '../../Filters/Filters.helpers';
import { getLeadTypeName, LeadViewReducerAction, LeadViewState, LeadsList, LeadsListLead } from './LeadView.helpers';

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean
    }
}

export const LeadViewList: FC<{
    state: LeadViewState;
    dispatch: React.Dispatch<LeadViewReducerAction>;
    filters?: Filters;
}> = ({ state, dispatch, filters }) => {
    const { store } = useStore();
    const { Api } = store;
    const { leads, limit, page, limitOptions } = state.leadsList;
    const [leadSearch, setLeadSearch] = useQueryParam('leadSearch', StringParam);

    const updateLeadSearch = debounce((term: string) => {
        dispatch({ name: 'setPage', payload: 1 });
        dispatch({ name: 'setLeadSearch', payload: term });
        setLeadSearch(term);
    }, 500);

    useEffect(() => {
        if (leadSearch) {
            updateLeadSearch(leadSearch);
        }
    }, []);

    const fetchData = async (pageNumber?: number, newLimit?: number) => {
        pageNumber = pageNumber || page;
        newLimit = newLimit || limit;
        const offset = (pageNumber - 1) * newLimit;

        dispatch({ name: 'setIsLoading', payload: true });

        const [response, error] = await handlePromise<{ data: { leads: LeadsListLead[]; count: number } }>(
            Api.client.post('/leads/list', {
                filters: { ...filters, search: state.leadSearch },
                context: 'list',
                limit: newLimit,
                offset,
                order: { column: 'created_at', direction: 'desc' }
            })
        );

        if (!response?.data || error) {
            dispatch({ name: 'setIsLoading', payload: false });
            return;
        }

        const { leads, count } = response.data;
        const payload: LeadsList = {
            ...state.leadsList,
            leads,
            leadsCount: count,
            page: pageNumber,
            limit: newLimit
        };
        dispatch({ name: 'setLeadsList', payload });
        dispatch({ name: 'setIsLoading', payload: false });
    };

    useAsyncEffect(fetchData, undefined, [filters, state.leadSearch]);

    const handlePageChange = async (pageNumber: number) => {
        if (pageNumber === state.leadsList.page) {
            return;
        }

        await fetchData(pageNumber);
    };

    const handleLimitChange = async (newLimit: number) => {
        await fetchData(1, newLimit);
    };

    const checkLeadCount = (): boolean => {
        return (state.leadsList.leadsCount || 0) <= 10000;
    };

    const exportToCsv = async () => {
        if (!checkLeadCount()) {
            alert(
                `You may only export up to 10,000 leads at a time. Please adjust your filters and date range accordingly. Current count: ${state
                    .leadsList.leadsCount || 0}`
            );
            return;
        }

        const [response, error] = await handlePromise<{ data: any }>(
            Api.client.post('/leads/csv', {
                filters,
                order: { column: 'created_at', direction: 'desc' }
            })
        );

        if (!response?.data || error) {
            return;
        }

        const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, 'leads-list');
        } else {
            const link = document.createElement('a');
            link.download = 'leads-list.csv';
            link.href = URL.createObjectURL(blob);
            link.click();
        }
    };

    const handleRowClick = ({ company_id, id, lead_customer_id }: LeadsListLead) => {
        const { agencyStore, router } = store;
        const { activeAgencyId } = agencyStore;
        const currentView = { ...router.currentView! };
        const leadDetailsRoute = getRouteWithContext('LeadDetailsByCustomer', router);
        const params = {
            agencyId: activeAgencyId,
            companyId: company_id,
            customerId: lead_customer_id,
            id,
            backRoute: currentView
        };

        router.goTo(leadDetailsRoute, params, store);
    };

    const friendlyUtmName = (utm?: string | null) => {
        if (!utm) {
            return 'Direct';
        }
        return friendlyUtmSourceNames[utm] || utm;
    };

    return (
        <AppCard>
            <ActionList>
                <InputText
                    className="clx-margin-bottom-0"
                    name="leadsSearch"
                    placeholder="Search"
                    defaultValue={leadSearch}
                    prefix={<Icon name="search" />}
                    onChange={event => updateLeadSearch(event.target.value)}
                />

                {leads.length > 0 && <ButtonPrimary onClick={exportToCsv}>Export Leads List</ButtonPrimary>}

                {state.isLoading && (
                    <div>
                        <CircularProgress size="20px" />
                    </div>
                )}
            </ActionList>

            {!state.isLoading && leads.length < 1 && (
                <AppListEmpty
                    title="There are no leads to show"
                    description="Either no leads exist, or you may need to adjust your query."
                />
            )}

            {!state.isLoading && leads.length > 0 && (
                <>
                    <Table>
                        <thead>
                            <tr>
                                <th>Customer</th>
                                <th>Created</th>
                                <th>First Touch</th>
                                <th>Last Touch</th>
                                <th>CLX Conversions</th>
                                <th>App Touches</th>
                                <th className="appointment-header">Appointment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderBy(leads, ['created_at'], ['desc']).map(lead => {
                                const {
                                    calendar_at,
                                    company_name,
                                    created_at,
                                    full_name,
                                    id,
                                    lead_type,
                                    phone_number,
                                    related_session_count,
                                    status,
                                    first_touch,
                                    last_touch,
                                    multi_touch
                                } = lead;
                                const isCancelled = status === LeadStatus.APPOINTMENT_CANCELLED;
                                const isPassed =
                                    DateTime.fromISO(calendar_at).toMillis() <
                                    DateTime.local()
                                        .startOf('day')
                                        .toMillis();

                                return (
                                    <tr key={id} className="lead-list-row" onClick={() => handleRowClick(lead)}>
                                        <td>
                                            {
                                                <>
                                                    <div className="lead-list-row-name">{full_name}</div>
                                                    <div className="lead-list-row-phone">
                                                        {phoneFormatter(phone_number)}
                                                    </div>
                                                </>
                                            }
                                        </td>
                                        <td>
                                            {
                                                <>
                                                    <div className="lead-list-row-created-date">
                                                        {DateTime.fromISO(created_at).toLocaleString(
                                                            DateTime.DATETIME_SHORT
                                                        )}
                                                    </div>
                                                    <div className="lead-list-row-company">{company_name}</div>
                                                </>
                                            }
                                        </td>
                                        <td>
                                            {first_touch === 'conversion logix' ? (
                                                <div className="lead-list-row-created-date">
                                                    <span style={{ width: '30px' }}>
                                                        <Icon name={'clxIcon'} />
                                                    </span>
                                                </div>
                                            ) : (
                                                friendlyUtmName(first_touch)
                                            )}
                                        </td>
                                        <td>
                                            {last_touch === 'conversion logix' ? (
                                                <div className="lead-list-row-created-date">
                                                    <span style={{ width: '30px' }}>
                                                        <Icon name={'clxIcon'} />
                                                    </span>
                                                </div>
                                            ) : (
                                                friendlyUtmName(last_touch)
                                            )}
                                        </td>
                                        <td>
                                            {multi_touch === 'conversion logix' ? (
                                                <div className="lead-list-row-created-date">
                                                    <span style={{ width: '30px' }}>
                                                        <Icon name={'clxIcon'} />
                                                    </span>
                                                </div>
                                            ) : (
                                                <span>{multi_touch || ''}</span>
                                            )}
                                        </td>
                                        <td>
                                            {
                                                <>
                                                    <div className="lead-list-row-created-date">
                                                        {getLeadTypeName(lead_type)}
                                                    </div>
                                                    <div className="lead-list-row-touches">{related_session_count}</div>
                                                </>
                                            }
                                        </td>
                                        <td>
                                            {lead_type === LeadType.SG && (isCancelled || isPassed) && (
                                                <div className="missed-appointment">
                                                    <div
                                                        className={classNames('appointment-icon', {
                                                            cancelled: isCancelled,
                                                            passed: !isCancelled && isPassed
                                                        })}
                                                    >
                                                        <Icon
                                                            name={isCancelled ? 'radioFilledCancel' : 'radioFilled'}
                                                        />{' '}
                                                        {isCancelled ? 'Cancelled' : 'Appt. Passed'}
                                                    </div>
                                                </div>
                                            )}

                                            {lead_type === LeadType.SG && !isCancelled && !isPassed && (
                                                <div className="upcoming-appointment">
                                                    <div className="upcoming-appointment-icon">
                                                        <Icon name="radioFilled" />
                                                    </div>
                                                    <div className="upcoming-appointment-details">
                                                        <div className="upcoming-appointment-text">Scheduled</div>
                                                        <div className="upcoming-appointment-date">
                                                            {DateTime.fromISO(calendar_at).toLocaleString(
                                                                DateTime.DATETIME_SHORT
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>

                    <div className="pagination-controls">
                        <InputSelect
                            name="rowCount"
                            label="Rows"
                            options={limitOptions}
                            value={{ label: `${limit}`, value: limit }}
                            onChange={(_, { value }) => handleLimitChange(value)}
                        />

                        <PaginationPrimary
                            pagesCount={Math.ceil(state.leadsList.leadsCount / state.leadsList.limit)}
                            page={state.leadsList.page}
                            onChange={(_, page) => handlePageChange(page)}
                        />
                    </div>
                </>
            )}
        </AppCard>
    );
};
