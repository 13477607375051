import React, { FC } from 'react';
import { observer } from 'mobx-react';
import DefaultRoutes from '../../../../../routes/DefaultRoutes';
import useStore from '../../../../../store/useStore';
import { FEATURES } from '../../../../../types/Features';
import { SideNavMenu } from '../../SideNavMenu';
import { SideNavMenuItem } from '../../SideNavMenuItem';
import { getSupportUrl } from '../../SideNav.helpers';
import { FEATURE_FLAGS } from '../../../../../types/FeatureFlag';
import { getRouteWithContext } from '../../../../../routes/routeUtils';

export interface MenuCompanyProps { }

export const MenuCompany: FC<MenuCompanyProps> = observer(props => {
    const { store } = useStore();
    const { features, isAdminRole, isAgencyRole, amountUnreadSmsMessages, hasAccess, router, activeUser, activeCompany } = store;

    const isUniversal = activeCompany?.is_universal || false;

    // Note: Set `previewFlags` to an empty array by default to avoid "can't read property 'includes' of `undefined`" error.
    const { previewFlags = [] } = activeUser || {};

    const hideCallUsNow = isUniversal || !features[FEATURES.call_us_now];
    const hideLiveChatLegacy = isUniversal || !features[FEATURES.chat];
    const hideLiveChat = isUniversal || !features[FEATURES.live_chat];
    const hideIncomeCalculator = isUniversal || !features[FEATURES.income_calculator];
    const hideConcessionManager = isUniversal || !features[FEATURES.concession_manager];
    const hideTextUsNow = isUniversal || !features[FEATURES.text_us_now] || !features[FEATURES.sms_messages];
    const hideScheduleGenie = isUniversal || !features[FEATURES.schedule_genie];
    const hideInsite = isUniversal || !features[FEATURES.insite];
    const hideMessages = isUniversal || !features[FEATURES.amount_twilio_numbers] && !features[FEATURES.sms_messages];
    const hasRealyncIntegration = previewFlags.includes('realync-integration') && features[FEATURES.realync];
    const hasLeadNurturingV2 = previewFlags.includes('lead-nurturing-v2');
    const hideChatBot = isUniversal || !previewFlags.includes(FEATURE_FLAGS.ENABLE_CHATBOT) || !features[FEATURES.chatbot];
    const hideChatBotAI = isUniversal || previewFlags.includes(FEATURE_FLAGS.ENABLE_CHATBOT_AI) || !features[FEATURES.chatbot_AI];

    const hideLeadNurturing = isUniversal || !features[FEATURES.lead_nurturing];

    const leadRoutes = [{ route: DefaultRoutes.CompanyLeadView }];
    const leadsItems = [...leadRoutes, { route: DefaultRoutes.CompanyInsiteMetricDataList, hidden: hideInsite }];
    // TODO: Update routes to use our `checkRouteAccess` function, rather than doing this...
    const appsItems = [
        {
            route: DefaultRoutes.CallUsNow,
            hidden: hideCallUsNow
        },
        {
            route: DefaultRoutes.LiveChatLegacy,
            hidden: hideLiveChatLegacy
        },
        {
            route: DefaultRoutes.LiveChat,
            hidden: hideLiveChat
        },
        {
            route: DefaultRoutes.ConcessionManagerList,
            hidden: hideConcessionManager
        },
        {
            route: DefaultRoutes.IncomeCalculator,
            hidden: hideIncomeCalculator
        },
        {
            route: DefaultRoutes.ScheduleGenieAppPage,
            hidden: hideScheduleGenie
        },
        {
            route: DefaultRoutes.TextUsNow,
            hidden: hideTextUsNow
        },
        {
            route: DefaultRoutes.Insite,
            hidden: hideInsite
        },
        { route: DefaultRoutes.WebNotifications },
        {
            route: DefaultRoutes.ChatBot,
            hidden: hideChatBot
        },
        {
            route: DefaultRoutes.LiveChatAIManualLeadSubmission,
            hidden: hideChatBotAI
        },
        {
            route: DefaultRoutes.ChatBotAIWidgetCode,
            hidden: true
        }
    ];

    const clarityV2 = { route: getRouteWithContext('ClarityDashboard', router) };

    const companyLeadNurturing = [
        { route: DefaultRoutes.DripScheduleEditorListPage },
        { route: DefaultRoutes.BlastScheduleEditorListPage, hidden: !hasLeadNurturingV2 },
        { route: DefaultRoutes.ListSegmentEditorListPage, hidden: !hasLeadNurturingV2 },
        { route: DefaultRoutes.CompanyAdvancedCustomerSearch, hidden: !hasLeadNurturingV2 },
        { route: DefaultRoutes.CompanyContactImport, hidden: !hasLeadNurturingV2 },
        { route: DefaultRoutes.EmailSenderProfile, hidden: !hasLeadNurturingV2 }
    ];

    const companySettingsItems = [
        { route: DefaultRoutes.CompanyEdit },
        { route: DefaultRoutes.EmailTemplatesList, hidden: isUniversal },
        { route: DefaultRoutes.ScheduleEditorListPage, hidden: isUniversal },
        { route: DefaultRoutes.CompanyFeatureFlags, hidden: isUniversal || (!isAdminRole && !isAgencyRole) },
        { route: DefaultRoutes.CompanyFeatures, hidden: isUniversal || (!isAdminRole && !isAgencyRole) },
        { route: DefaultRoutes.RemoteAuthList, hidden: isUniversal },
        { route: DefaultRoutes.ApiKeyList, hidden: isUniversal },
        { route: DefaultRoutes.CrmList, hidden: isUniversal },
        { route: DefaultRoutes.MatchbackImport, hidden: isUniversal },
        { route: DefaultRoutes.QuestionDefinitionPresetPage, hidden: isUniversal },
        { route: DefaultRoutes.CompanyIntegrations, hidden: (!isAdminRole && !isAgencyRole) || !hasRealyncIntegration || isUniversal }
    ];

    const companyToolsItems = [
        { route: DefaultRoutes.CompanyLists },
        { route: DefaultRoutes.CompanyEmailViewer },
        { route: DefaultRoutes.CompanyOptOutQueueList },
        { route: DefaultRoutes.CompanyAuditLog },
        { route: DefaultRoutes.CompanyCssToolsList },
        { route: DefaultRoutes.CompanyCsvImport }
    ];

    return (
        <SideNavMenu>
            {isUniversal ? (
                <SideNavMenuItem name="dashboard" icon="home" route={DefaultRoutes.CompanyEdit} />
            ) : (
                <SideNavMenuItem name="dashboard" icon="home" route={DefaultRoutes.CompanyDashboard} />
            )}

            {!isUniversal && (
            hideInsite ? (
                <SideNavMenuItem name="leads" label="Leads" icon="user" route={DefaultRoutes.CompanyLeadView} />
            ) : (
                <SideNavMenuItem
                    name="leads"
                    label="Leads"
                    icon="user"
                    submenu={{ items: leadsItems }}
                    route={leadsItems[0].route}
                />
            )
            )}


            {!hideMessages && (
                <SideNavMenuItem
                    name="messages"
                    icon="chat"
                    notificationCount={amountUnreadSmsMessages}
                    route={DefaultRoutes.CompanyMessages}
                />
            )}

            {!isUniversal && (
                            <SideNavMenuItem
                name="apps"
                label="Apps"
                icon="apps"
                submenu={{ items: appsItems }}
                route={appsItems.find(item => !item.hidden)?.route}
            />
            )}

            {!isUniversal && (
                <SideNavMenuItem name="customize" icon="paintBrush" route={DefaultRoutes.ApplicationSettings} />
            )}
            
            {hasAccess('admin.dashboard') && !isUniversal && (
                <SideNavMenuItem name="clarity_v2" label="CLarity Attribution" icon="analytics" route={clarityV2.route} />
            )}

            <SideNavMenuItem hidden={hideLeadNurturing} name="lead_nurturing" label="Lead Nurturing" icon="mailOutline" route={DefaultRoutes.DripScheduleEditorListPage} submenu={{ items: companyLeadNurturing }} />

            {!isUniversal && (
                            <SideNavMenuItem
                name="company"
                label="Company"
                icon="company"
                submenu={{ items: companySettingsItems }}
                route={companySettingsItems[0].route}
            />
            )}


            {hasAccess('admin.dashboard') && (
                <SideNavMenuItem
                    name="tools"
                    label="Tools"
                    icon="filter"
                    route={companyToolsItems[0].route}
                    submenu={{ items: companyToolsItems }}
                />
            )}

            <li className="side-nav-divider" />

            <SideNavMenuItem
                name="support"
                label="Support"
                icon="support"
                onClick={() => window.open(getSupportUrl(store), '_blank')}
            />
        </SideNavMenu>
    );
});
