import React from 'react';
import BigCalendar, { View as BigCalendarView } from 'react-big-calendar';
import * as moment from 'moment-timezone';
import { observer } from 'mobx-react';
import useStore from '../../../store/useStore';
import { LeadTypeAbbr } from '../../../constants';
import { numberFormatter } from '../../../util/formatters';
import {
    LeadStatsMap,
    LeadViewCalendarEvent,
    LeadViewReducerAction,
    LeadViewState,
    LeadViewStats,
    selectCalendarLeads
} from './LeadView.helpers';
import { AppCard, AppCardGrid, StatCard, useSnackbar } from '../../../components-v2/shared';
import { useFilters } from '../../Filters/Filters';
import { StringParam, useQueryParam } from 'use-query-params';
import { handlePromise, useAsyncEffect } from '@lambdacurry/component-library';
import { DashboardData } from '../../Dashboard/HomeDashboard.helpers';
import { CircularProgress } from '@material-ui/core';
import { DateRangePreset, InitialFilters } from '../../Filters/Filters.helpers';
import { getRouteWithContext } from '../../../routes/routeUtils';

import './lead-view-calendar.scss';

export const LeadViewCalendar: React.FC<{
    state: LeadViewState;
    dispatch: React.Dispatch<LeadViewReducerAction>;
}> = observer(({ state, dispatch }) => {
    const { store, agencyStore } = useStore();
    const { activeCompanyId, Api, router } = store;
    const { activeAgencyId } = agencyStore;
    const { addSnackbar } = useSnackbar();

    const { filters, setFilters } = useFilters();
    const [calendarView, setCalendarView] = useQueryParam('calendarView', StringParam);

    const fetchCalendarData = async () => {
        if (!filters?.dateRange.custom?.start || !filters?.dateRange.custom.end) {
            return;
        }

        const [response, error] = await handlePromise(
            Api.client.post('/leads/list', {
                filters,
                context: 'calendar'
            })
        );

        if (!response || error) {
            addSnackbar('Failed to fetch calendar data.', { variant: 'error' });
            return;
        }

        dispatch({ name: 'setCalendarData', payload: response.data });
    };

    // Re-fetch analytics data whenever the filters, context, activeCompanyId, or activeAgencyId changes.
    const fetchAnalyticsData = async () => {
        if (!filters) {
            return;
        }

        dispatch({ name: 'setIsLoading', payload: true });

        const [response, error] = await handlePromise<{ data: DashboardData }>(
            Api.client.post('/analytics/dashboard', { filter: filters })
        );

        if (!response?.data || error) {
            dispatch({ name: 'setIsLoading', payload: false });
            return console.error('error: ', error);
        }

        const { totals } = response.data;
        const statsData: LeadViewStats = {
            leads: totals.leads,
            tours: totals.leadsByModule.SG || 0,
            chats: totals.leadsByModule.CH || 0,
            livechat: totals.leadsByModule.LC || 0,
            offers: totals.leadsByModule.CM || 0
        };

        dispatch({ name: 'setLeadViewStats', payload: statsData });
        dispatch({ name: 'setIsLoading', payload: false });
    };

    useAsyncEffect(fetchCalendarData, undefined, [filters]);
    useAsyncEffect(fetchAnalyticsData, undefined, [filters, activeCompanyId, activeAgencyId]);

    const localizer = BigCalendar.momentLocalizer(moment);

    const eventPropGetter = (event: LeadViewCalendarEvent) => ({
        className: `${LeadTypeAbbr[event.lead_type].toLowerCase()}-calendar-event view-${state.calendarView}`
    });

    const handleEventClick = (event: LeadViewCalendarEvent, e: React.SyntheticEvent) => {
        const currentView = { ...router.currentView! };
        const leadDetailsRoute = getRouteWithContext('LeadDetailsByCustomer', router);

        router.goTo(
            leadDetailsRoute,
            {
                agencyId: agencyStore.activeAgencyId,
                companyId: store.activeCompanyId,
                customerId: event.lead_customer_id,
                id: event.id,
                backRoute: currentView
            },
            store
        );
    };

    const onRangeChange = async (
        range:
            | {
                start: Date;
                end: Date;
            }
            | Date[]
    ) => {
        if (!Array.isArray(range)) {
            const { start, end } = range as { start: Date; end: Date };
            return setFiltersWithDateRange(start, end);
        }

        if ((range as Date[]).length) {
            const dateRangeArray = range as Date[];
            const start = dateRangeArray[0];
            const end = dateRangeArray[dateRangeArray.length - 1];
            return setFiltersWithDateRange(start, end);
        }

        function setFiltersWithDateRange(start: Date, end: Date) {
            setFilters({
                ...InitialFilters,
                ...filters,
                dateRange: {
                    preset: DateRangePreset.CUSTOM,
                    timezone: filters?.dateRange.timezone || InitialFilters.dateRange.timezone,
                    custom: {
                        start,
                        end
                    }
                }
            });
        }
    };

    return (
        <>
            <AppCardGrid>
                <AppCard className="lead-view-calendar-card">
                    <BigCalendar
                        popup
                        defaultView={(calendarView as BigCalendarView) || 'month'}
                        onView={view => setCalendarView(view, 'replaceIn')}
                        views={['month', 'week', 'day']}
                        localizer={localizer}
                        events={selectCalendarLeads(state)}
                        onSelectEvent={handleEventClick}
                        step={30}
                        eventPropGetter={eventPropGetter}
                        onRangeChange={onRangeChange}
                        className={`lead-view-calendar lead-view-calendar-${calendarView || 'month'}`}
                    />
                </AppCard>

                {state.isLoading && <CircularProgress size="24px" />}

                {!state.isLoading && (
                    <>
                        {Object.keys(LeadStatsMap).map(key => {
                            const { icon, label } = LeadStatsMap[key];
                            if (!state.leadViewStats) {
                                return null;
                            }

                            return (
                                <StatCard
                                    key={label}
                                    label={label}
                                    value={numberFormatter(state.leadViewStats[key])}
                                    color="blue"
                                    icon={icon}
                                />
                            );
                        })}
                    </>
                )}
            </AppCardGrid>
        </>
    );
});
