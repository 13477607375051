import * as Yup from 'yup';

export enum QuestionResponseFormat {
    text = 'text',
    select = 'select'
}

export const friendlyResponseFormat = {
    text: 'Free form text',
    select: 'Select from list'
}

export const QuestionDefinitionAdHocTag = 'Ad hoc';

export interface QuestionPicklistItem {
    label: string;
    value: string;
}

export enum QuestionDefinitionLevel {
    global = 'global',
    business_category = 'business_category',
    agency_business_category = 'agency_business_category',
    agency = 'agency',
    company = 'company'
}

export const friendlyQuestionDefinitionLevel = {
    global: 'Global',
    business_category: 'Business Category',
    agency: 'Agency',
    agency_business_category: 'Agency: Business Category',
    company: 'Company'
}

export interface QuestionDefinition {
    id?: number;
    agency_id?: number;
    business_category_id?: number;
    company_id?: number;
    level: QuestionDefinitionLevel;
    tag: string;
    required: boolean;
    text: string;
    response_format: QuestionResponseFormat;
    picklist?: QuestionPicklistItem[];
}

export interface QuestionResponse {
    auto_lead_id: number;
    company_id: number;
    created_at: string;
    id: number;
    response: string;
    tag: string;
    text: string;
}

export interface QuestionBundle {
    questions: QuestionDefinition[];
}

export const QuestionBundleValidator = Yup.object().shape({
    questions: Yup.array().of(
        Yup.object().shape({
            text: Yup.string().required(),
            response_format: Yup.string().required(),
            picklist: Yup.array().when(["response_format"], {
                is: (response_format) => response_format === 'select',
                then: Yup.array().required('Must provide answer options when response format is Select')
            })
        })
    )
});