import React from 'react';
import { AppCard, AppCardDivider, AppCardHeader, AppSectionHeader, ListGroup, ListGroupItem, Panel } from '../../../../components-v2/shared';
import { DateTime } from 'luxon';
import { Lead, LeadType } from '../../../../types/Lead';
import { underscoreToCapitalize } from '../LeadDetails.helpers';

const tagColors = ['purple', 'red', 'cyan', 'white', 'light-red']

function getRandomElement(array: string[]) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
}

export const AppEventLiveChat: React.FC<{ lead: Lead }> = ({ lead }) => {
    const data = lead.livechat;
    if (!data) return null;

    const UTMTag: React.FC<{ val: string; className: string, label?: string }> = ({ val, className, label }) => {
        if (!val || val === '') return null;

        return (
            <div className={className}>
                {label &&
                    <h5>
                        {label}
                    </h5>
                }
                <span className={label && 'bold'}>
                    {val}
                </span>
            </div>
        );
    };

    return (
        <AppCard>
            <AppCardHeader
                icon={{ name: 'chat' }}
                title="Live Chat"
                description={DateTime.fromISO(lead.created_at as string).toLocaleString(DateTime.DATETIME_SHORT)}
            />
            <AppSectionHeader title="Chat Topics" as="Header" />
            <Panel className="app-event-card-live-chat-comments customer-lead-journey-labels">
                {
                    data.topics.map((tag: any, index: number) => {
                        return (
                            <UTMTag
                                key={index}
                                val={`${index}.  ${tag}`}
                                className={`customer-lead-journey-label ${getRandomElement(tagColors) || 'generic-color'}`}
                            />
                        );
                    })
                }
            </Panel>

            {
                data?.last_pages?.length > 0 &&
                <>
                    <AppCardDivider />
                    <AppSectionHeader title="Site Info" as="Header" />
                    <Panel className="app-event-card-live-chat-comments customer-lead-journey-labels">
                        {
                            Object.entries(data?.last_pages[0]).map(([key, value]) => {
                                if (key === 'opened_at') {
                                    return null;
                                }
                                return (
                                    <div className='customer-lead-journey-label'>
                                        <span className='bold'>{underscoreToCapitalize(key)}</span>: {' '}{value}
                                    </div>
                                )
                            })
                        }
                    </Panel>
                </>
            }

            {
                data?.tags?.length > 0 &&
                <>
                    <AppCardDivider />
                    <AppSectionHeader title="Tags" as="Header" />
                    <Panel className="app-event-card-live-chat-comments customer-lead-journey-labels">
                        {
                            data.tags.map((tag: string, index: number) => {
                                return (
                                    <UTMTag
                                        key={index}
                                        val={`${tag}`}
                                        className={`customer-lead-journey-label ${getRandomElement(tagColors) || 'generic-color'}`}
                                    />
                                );
                            })
                        }
                    </Panel>
                </>
            }
            {lead.opened_from_chatbot_ai && (
                <>
                    <AppCardDivider />
                    <AppCard>
                        <AppCardHeader
                            icon={{ name: 'chat' }}
                            title="Claire AI+"
                            description={DateTime.fromISO(lead.created_at as string).toLocaleString(
                                DateTime.DATETIME_SHORT
                            )}
                        />
                        <ListGroup>
                            <ListGroupItem icon={{ name: 'deal' }} label="Lead created by">
                                {(LeadType.LC === lead.lead_type) && 'Live Chat'}
                            </ListGroupItem>
                        </ListGroup>
                    </AppCard>
                </>
            )}

            {
                (data?.statistics && !Array.isArray(data?.statistics)) &&
                <>
                    <AppCardDivider />
                    <AppSectionHeader title="Statistics" as="Header" />
                    <Panel className="app-event-card-live-chat-comments customer-lead-journey-labels">
                        {
                            Object.entries(data.statistics).map(([key, value]) => {
                                return (
                                    <UTMTag
                                        label={underscoreToCapitalize(key)}
                                        key={key}
                                        val={`${value}`}
                                        className={`customer-lead-journey-label white align-center`}
                                    />
                                )
                            })
                        }
                    </Panel>
                </>
            }

            {
                (data?.geolocation && !Array.isArray(data?.geolocation)) &&
                <>
                    <AppCardDivider />
                    <AppSectionHeader title="Geolocation" as="Header" />
                    <Panel className="app-event-card-live-chat-comments customer-lead-journey-labels">
                        {
                            Object.entries(data.geolocation).map(([key, value]) => {
                                return (
                                    <UTMTag
                                        label={underscoreToCapitalize(key)}
                                        key={key}
                                        val={`${value}`}
                                        className={`customer-lead-journey-label white align-center`}
                                    />
                                )
                            })
                        }
                    </Panel>
                </>
            }
        </AppCard>
    );
};
