import { Tabs } from '@lambdacurry/component-library';
import { observer } from 'mobx-react';
import React, { useEffect, useReducer } from 'react';
import useStore from '../../../store/useStore';
import { LeadViewInitialState, LeadViewReducer } from './LeadView.helpers';
import { LeadViewCalendar } from './LeadViewCalendar';
import { LeadViewList } from './LeadViewList';
import { AppSectionHeader } from '../../../components-v2/shared';
import { FiltersSelector, useFilters } from '../../Filters/Filters';
import { DateTime } from 'luxon';
import './lead-view.scss';
import { DateRangePreset } from '../../Filters/Filters.helpers';
import { BooleanParam, useQueryParam } from 'use-query-params';

export const LeadView = observer(() => {
    const { store } = useStore();
    const { router } = store;

    const [state, dispatch] = useReducer(LeadViewReducer, LeadViewInitialState);
    const { filters, setFilters } = useFilters();

    const [isListView, setListView] = useQueryParam('listView', BooleanParam);

    useEffect(() => {
        if (isListView) {
            dispatch({ name: 'setTabValue', payload: 1 });
        }
    }, [router.currentPath]);

    return (
        <div className="lead-view">
            <AppSectionHeader title="Leads">
                <div className="filters">
                    <FiltersSelector
                        initialFilters={{
                            dateRange: {
                                preset: DateRangePreset.CUSTOM,
                                timezone: filters?.dateRange.timezone || DateTime.local().zoneName,
                                custom: {
                                    start:
                                        filters?.dateRange.custom?.start ||
                                        DateTime.local()
                                            .startOf('month')
                                            .toJSDate(),
                                    end:
                                        filters?.dateRange.custom?.end ||
                                        DateTime.local()
                                            .endOf('month')
                                            .toJSDate()
                                }
                            }
                        }}
                        showDateRange={state.tabValue === 1}
                    />
                </div>
            </AppSectionHeader>
            <Tabs
                value={state.tabValue}
                onChange={(event, value) => {
                    if (value === 0) {
                        setListView(false, 'replaceIn');
                        setFilters({
                            dateRange: {
                                preset: DateRangePreset.CUSTOM,
                                timezone: filters?.dateRange.timezone || DateTime.local().zoneName,
                                custom: {
                                    start: DateTime.local()
                                        .startOf('month')
                                        .toJSDate(),
                                    end: DateTime.local()
                                        .endOf('month')
                                        .toJSDate()
                                }
                            }
                        });
                    } else {
                        setListView(true, 'replaceIn');
                        setFilters({
                            dateRange: {
                                timezone: filters?.dateRange.timezone || DateTime.local().zoneName,
                                preset: DateRangePreset.LAST7
                            }
                        });
                    }

                    dispatch({ name: 'setTabValue', payload: value });
                }}
                tabs={[
                    {
                        label: 'Lead Calendar',
                        render: <LeadViewCalendar state={state} dispatch={dispatch} />
                    },
                    {
                        label: 'Lead List',
                        render: <LeadViewList state={state} dispatch={dispatch} filters={filters} />
                    }
                ]}
            />
        </div>
    );
});
