import React from 'react';
import { observer } from 'mobx-react';
import { AppHeader, AppPage, ActionList, InputSwitch, useSnackbar } from '../../components-v2/shared';
import { TextUsNowForm } from './TextUsNowForm';
import DefaultRoutes from '../../routes/DefaultRoutes';
import useStore from '../../store/useStore';
import { handlePromise, useAsyncEffect } from '../../util/async';
import { FormikHelpers } from 'formik';
import { TwilioNumber } from '../../types/TwilioNumber';
import { AppsName } from '../../types';

export const TextUsNowEdit: React.FC<{ textUsId: number }> = observer(({ textUsId }) => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { fetchTextUs, fetchSchedules, fetchTwilioNumbers, schedules, twilioNumbers, textUs, router, Api } = store;
    const { companyId } = router.params;
    const textUsItem = textUs.getItem(textUsId);
    const loading = !twilioNumbers.length || !textUs.length || !textUsItem;
    const twilioNumberOptions = twilioNumbers?.values?.find(
        (item: TwilioNumber) => item?.company_id === textUsItem.company_id
    );
    const fetchData = async () => {
        await fetchTextUs();
        await fetchSchedules();
        await fetchTwilioNumbers();
    };
    useAsyncEffect(fetchData, undefined, []);

    if (!companyId) {
        router.goTo(DefaultRoutes.Home, {}, store);
        return null;
    }

    const toggleTextUs = async () => {
        try {
            await textUs.update({ ...textUsItem, active: !textUsItem.active });
            addSnackbar(`Text us now has been ${textUsItem.active ? 'deactivated' : 'activated'}.`, {
                variant: 'success'
            });
            const [response, error] = await handlePromise(
                Api.client.patch(`public_roles/updateActiveModules`, {
                    toggleType: textUsItem.active ? 'remove' : 'add',
                    company_id: companyId,
                    moduleName: AppsName.TU
                })
            );

            if (!response?.data || error) {
                return console.error('error: ', error);
            }
        } catch (error) {
            console.error(error.response.data);
            addSnackbar(`Failed to ${textUsItem.active ? 'deactivate' : 'activate'} Text Us Now.`, {
                variant: 'error'
            });
        }
    };

    const handleSubmit = async (values: any, actions: FormikHelpers<any>) => {
        try {
            await textUs.update({ ...values, schedule_id: values.schedule_id || null });
            addSnackbar(`Text us now has been updated.`, {
                variant: 'success'
            });
        } catch (error) {
            actions.setErrors(error.response.data);
            addSnackbar(`Failed to update text us now.`, {
                variant: 'success'
            });
        }
    };

    const initialValues = {
        ...textUsItem,
        active: !!textUsItem?.active,
        phone_num_friendly: twilioNumberOptions?.phone_num_friendly,
        phone_num_full: twilioNumberOptions?.phone_num_full,
        twilio_id: twilioNumberOptions?.id
    };

    return (
        <>
            <AppPage loading={loading}>
                <AppHeader title="Text Us Now" icon={{ name: 'sms', color: 'blue' }}>
                    <ActionList position="end">
                        <InputSwitch
                            onClick={toggleTextUs}
                            labelOn="Active"
                            labelOff="Inactive"
                            labelPlacement="start"
                            checked={!!textUsItem?.active}
                        />
                    </ActionList>
                </AppHeader>

                <TextUsNowForm
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    textUsId={textUsId}
                    companyId={parseInt(companyId, 10)}
                    schedules={schedules}
                    twilioNumbers={twilioNumbers}
                />
            </AppPage>
        </>
    );
});
